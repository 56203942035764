import React from 'react';

import style from './About.module.css'
import { Link } from 'react-router-dom';

export default function About() {
    return (
        <div className={style.mainDiv}>
            <h1>Coming Soon</h1>
        </div>
    )
}